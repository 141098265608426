import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {Download} from '@styled-icons/evaicons-solid/Download';
import {getCdnUrl} from '../../Utils/CdnUtil';

const pageSize = 20;
const collectionName = 'meeting';

function InfoMeetings(props) {
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [searchName, setSearchName] = React.useState('');
  const [tmpSearchName, setTmpSearchName] = React.useState('');
  const [searchDate, setSearchDate] = React.useState('');
  const [tmpSearchDate, setTmpSearchDate] = React.useState('');

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {};
      if (searchName) {
        extraQuery['title'] = {
          $regex: searchName,
        };
      }
      if (searchDate) {
        extraQuery.date = searchDate;
      }

      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['-created'],
        paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, searchDate, searchName]);

  React.useEffect(() => {
    async function fetchData() {
      fetchDocumentList();
    }

    fetchData();
  }, [fetchDocumentList]);

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>會議記錄</h1>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          marginBottom: 30,
          gap: 10,
        }}>
        <Ant.Input
          value={tmpSearchName}
          onChange={(e) => setTmpSearchName(e.target.value)}
          placeholder="請輸入關鍵字"
          style={{flex: '2 0 280px', borderRadius: 100, height: 36}}
        />

        <Ant.Input
          value={tmpSearchDate}
          type="date"
          onChange={(e) => setTmpSearchDate(e.target.value)}
          style={{flex: '1 0 160px', borderRadius: 100, height: 36}}
        />

        <div style={{display: 'flex'}}>
          <Ant.Button
            type="primary"
            onClick={() => {
              setSearchName(tmpSearchName);
              setSearchDate(tmpSearchDate);
            }}>
            搜尋
          </Ant.Button>
          <Ant.Button
            type="link"
            style={{marginLeft: 6}}
            onClick={() => {
              setTmpSearchName('');
              setSearchName('');
              setTmpSearchDate('');
              setSearchDate('');
            }}>
            清除
          </Ant.Button>
        </div>
      </div>

      <Ant.Table
        columns={[
          {
            title: '標題',
            key: 'title',
            render: (_, record) => {
              return (
                <div>
                  <div style={{color: '#666'}}>{record.date}</div>
                  <h3>{record.title}</h3>
                </div>
              );
            },
          },
          {
            title: '下載',
            key: 'file',
            render: (_, record) => {
              if (!record.file) {
                return null;
              }

              return (
                <Ant.Button
                  type="link"
                  target={'_blank'}
                  href={getCdnUrl(record.file)}>
                  <Download size={24} />
                </Ant.Button>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

export default withPageEntry(InfoMeetings);
